<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? isClone ? '应用克隆' : '应用编辑' : '应用创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item label="名称" prop="name" :wrapper-col="wrapperCol">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="应用版本"
        prop="version"
        :wrapper-col="wrapperCol"
      >
        <a-input v-model="form.version" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="groups" :wrapper-col="wrapperCol">
        <group-select
          v-model="form.groups"
          source-type="application"
        ></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-form-model-item
        label="所属操作系统"
        prop="os"
        :wrapper-col="wrapperCol"
      >
        <source-select
          v-model="form.os"
          :disabled="isUpdate && !isClone"
          source-type="os"
        ></source-select>
      </a-form-model-item>
      <a-form-model-item
        label="关联中间件"
        prop="middlewares"
        :wrapper-col="wrapperCol"
      >
        <multi-source-select
          v-model="form.middlewares"
          source-type="middleware"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item
        label="关联数据库"
        prop="databases"
        :wrapper-col="wrapperCol"
      >
        <multi-source-select
          v-model="form.databases"
          source-type="database"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item
        label="描述"
        prop="description"
        :wrapper-col="wrapperCol"
      >
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="monitorPort" :wrapper-col="wrapperCol">
        <question-icon
          slot="label"
          title="监控端口"
          description="用于端口监控"
        ></question-icon>
        <a-input
          v-model="form.monitorPort"
          :disabled="isUpdate && !isClone"
          :max="65535"
          :min="1"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="monitorURL" :wrapper-col="wrapperCol">
        <question-icon
          slot="label"
          title="监控 URL"
          description="用于 HTTP 监控"
        ></question-icon>
        <a-input
          v-model="form.monitorURL"
          :disabled="isUpdate && !isClone"
          placeholder="以 http:// 或 https:// 开头"
        ></a-input>
      </a-form-model-item>
<!--      <a-form-model-item prop="command" :wrapper-col="wrapperCol">-->
<!--        <question-icon-->
<!--          slot="label"-->
<!--          title="命令行"-->
<!--          description="用于进程监控，支持正则匹配"-->
<!--        ></question-icon>-->
<!--        <a-input v-model="form.command" :disabled="isUpdate && !isClone"></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getApplication,
  updateApplication,
  createApplication
} from '@/api/application'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect.vue'
import SourceSelect from '@/components/select/SourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    MultiSourceSelect,
    QuestionIcon,
    SourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      isClone: false,
      // 避免抽屉打开不生效
      wrapperCol: {
        span: 20
      },
      form: {
        id: '',
        name: '',
        version: '',
        monitorPort: '',
        monitorURL: '',
        // command: '',
        os: undefined,
        groups: undefined,
        middlewares: undefined,
        databases: undefined,
        description: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        os: [
          {
            message: '请选择所属操作系统',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show (id, isClone) {
      if (id) {
        this.isUpdate = true
        this.isClone = isClone
        getApplication(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              version: data.version,
              os: isClone ? undefined : data.os,
              monitorPort: data.monitor_port,
              monitorURL: isClone ? '' : data.monitor_url,
              // command: data.command,
              groups: data.groups,
              middlewares: data.middlewares,
              databases: data.databases,
              description: data.description
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        version: '',
        monitorPort: '',
        monitorURL: '',
        // command: '',
        os: undefined,
        groups: undefined,
        middlewares: undefined,
        databases: undefined,
        description: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name,
            version: this.form.version,
            description: this.form.description
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (this.form.middlewares) {
            params.middleware_ids = this.form.middlewares.map(
              middleware => middleware.id
            )
          }
          if (this.form.databases) {
            params.database_ids = this.form.databases.map(
              database => database.id
            )
          }
          if (this.isUpdate && !this.isClone) {
            updateApplication(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            // 创建
            params.os_id = this.form.os.id
            if (this.form.monitorPort) {
              params.monitor_port = this.form.monitorPort
            }
            if (this.form.monitorURL) params.monitor_url = this.form.monitorURL
            // if (this.form.command) params.command = this.form.command
            createApplication(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
